<template>
  <div>
    <en-grade-editor ref="gradeEditor" type-text="分类" :api="categoryApi" :params-names="{ id: 'id', text: 'name' }"
      :btns="itemBtns" :maxLevel="3" @add-click="handleAddCat" />
    <!--添加、编辑分类dialog-->
    <el-dialog width="500px" :visible.sync="dialogCatVisible" :close-on-click-modal="false"
      :close-on-press-escape="false">
      <div slot="title" class="goods-picker-title">
        <span></span>
        {{ catForm.id ? '编辑分类' : '添加分类' }}
      </div>
      <el-form :model="catForm" :rules="catRules" ref="catForm" label-width="100px">
        <!--分类名称-->
        <el-form-item label="分类名称" prop="name">
          <el-input maxlength="20" v-model.trim="catForm.name"></el-input>
        </el-form-item>
        <!--排序-->
        <el-form-item label="排序" prop="category_order">
          <el-input-number v-model="catForm.category_order" controls-position="right" :min="0" :max="99999" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogCatVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submitCatForm()" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as API_Category from '@/api/category'

export default {
  name: 'categoryList',
  data () {
    return {
      itemBtns: [
        { text: '编辑', onClick: this.handleEditCat },
        { text: '删除', onClick: this.handleDeleteCat, color: 'red' }
      ],
      // 添加、编辑分类 dialog
      dialogCatVisible: false,
      // 添加、编辑分类 表单
      catForm: {},
      // 添加、编辑分类 表单规则
      catRules: {
        name: [
          { required: true, message: '请输入分类名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' },
          { validator: this.validateName, trigger: "blur" }
        ]
      },
      categoryApi: '/admin/goods/giftCategory/@id/listWithChildren'
    }
  },
  methods: {
    validateName (rule, value, callback) {//分类名称重复效验
      const { parent_id, name, id } = this.catForm
      API_Category.getcheckNameUsable(parent_id, { parent_id: parent_id, name: name, id: id }).then(res => {
        if (res == false) {
          callback(new Error("当前分类下已有该名称"))
        } else {
          callback()
        }
      })
    },
    /** 添加分类 */
    handleAddCat (level, parent, parentArray) {
      this.catForm = {
        parent_id: parent ? parent.id : 0,
        category_order: 0,
        name: ''
      }
      this.dialogCatVisible = true
    },

    /** 编辑分类 */
    handleEditCat (cat, parent, parentArray) {
      this.catForm = {
        parent_id: parent ? parent.id : 0,
        ...cat,
        name: cat.name
      }
      this.dialogCatVisible = true
    },

    /** 添加、编辑分类 表单提交 */
    submitCatForm () {
      this.$refs.catForm.validate((valid) => {
        if (valid) {
          const { id } = this.catForm
          if (!id) {
            API_Category.addGiftCategory(this.catForm).then(() => {
              this.dialogCatVisible = false
              this.$message.success('保存成功！')
              this.$refs.catForm.resetFields()
              this.$refs['gradeEditor'].refresh('add')
            })
          } else {
            API_Category.editGiftCategory(id, this.catForm).then(response => {
              this.$message.success('保存成功！')
              this.dialogCatVisible = false
              this.$refs.catForm.resetFields()
              this.$refs['gradeEditor'].refresh('edit', {
                category_order: response.category_order,
                name: response.name
              })
            })
          }
        } else {
          // this.$message.error('表单填写有误，请检查！')
          return false
        }
      })
    },
    /** 删除分类确认 */
    handleDeleteCat (cat) {
      this.$confirm('确定要删除这个分类吗？', '提示', { type: 'warning' }).then(() => {
        API_Category.deleteGiftCategory(cat.id).then(() => {
          this.$message.success('删除成功！')
          this.$refs['gradeEditor'].refresh('delete')
        })
      }).catch(() => { })
    }
  }
}
</script>

<style lang="scss" scoped>
.goods-picker-title {
  border-bottom: 1px solid #ccc;
  height: 51px;
  line-height: 51px;
  text-align: left;
  background-color: rgba(224, 228, 238, 1);
  font-weight: 600;
  border-radius: 5px 5px 0 0;
}

.goods-picker-title span {
  background-color: #1a43a9;
  width: 4px;
  height: 14px;
  display: inline-block;
  margin-right: 14px;
  margin-bottom: 3px;
}

/deep/ .el-dialog__header {
  padding: 0;
}
</style>
